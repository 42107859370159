<template>
    <ResetPasswordComponent />
</template>

<script>
import ResetPasswordComponent from '../components/auth/ResetPasswordComponent.vue';

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordComponent,
  },
};

</script>

<style scoped>

</style>
